import { Button, Modal, Table } from 'react-bootstrap'
import Flex from 'components/common/Flex'
import useUtils from 'hooks/useUtils'

type Props = {
  stat: {
    date: string;
    cruises: any[];
  };
  show: boolean;
  onClose: Function;
};

const COLS_COUNT = 2

export default function BookingStatCruisesModal({ stat, onClose }: Props) {
  const { formatDate } = useUtils()

  /**
   * Closes the modal.
   */
  const closeModal = () => {
    onClose()
  }

  return (
    <Modal show onHide={closeModal}>
      <Modal.Body>
        <Flex alignItems="center" className="py-3 justify-content-center">
          <p className="fw-bold m-0">
            Cruise-IDs for {formatDate(stat.date, 'DD MMM YYYY')}
          </p>
        </Flex>
        <Table responsive striped hover>
          <thead>
            <tr className="text-center">
              <th scope="col">
                <h6 className="text-700 fw-semibold">Cruise ID</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Count</h6>
              </th>
            </tr>
          </thead>
          <tbody className="fs-10">
            <tr>
              {!stat.cruises.length && (
                <td colSpan={COLS_COUNT} className="text-center">
                  Empty results
                </td>
              )}
            </tr>
            {stat.cruises.map((result, index) => (
              <tr key={index} className="text-center">
                <td>{result.cruise_id}</td>
                <td>{result.count}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-flex justify-content-end">
          <Button variant="falcon-default" onClick={closeModal}>
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
