import Bookings from 'modules/simple-crm/components/contacts/contact-details/Bookings'
import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import bookingService from 'services/booking.service'

export default function BookingsPage() {
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])

  /**
   * Fetch latest bookings on mount
   */
  useEffect(() => {
    getLatestBookings()
  }, [])

  /**
   * Fetch latest bookings
   */
  const getLatestBookings = async () => {
    setLoading(true)

    // Fetch latest bookings from the API
    const { data } = await bookingService.latest()

    setLoading(false)
    setList(data)
  }

  return (
    <div id="page-bookings">
      <Card className="w-100">
        <Card.Header className="bg-light fw-bold">
          <div className="d-flex align-items-center gap-2">Latest Bookings</div>
        </Card.Header>
        <Card.Body>
          <Bookings loading={loading} bookings={list} />
        </Card.Body>
      </Card>
    </div>
  )
}
