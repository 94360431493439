import SubtleBadge from 'components/common/SubtleBadge'
import { app } from 'config'
import useUtils from 'hooks/useUtils'
import { useEffect, useState } from 'react'
import { Card, Spinner, Table } from 'react-bootstrap'
import sessionService from 'services/session.service'

const steps = {
  1: 'Passengers',
  2: 'Fare',
  3: 'Cabin',
  4: 'Details',
  5: 'Payment',
}

const COL_COUNT = 6

export default function SessionsPage() {
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const { monify } = useUtils()

  /**
   * Fetch latest sessions on mount
   */
  useEffect(() => {
    getLatestSessions()

    // Call getLatestSessions again every 10mins
    const apiCallInterval = setInterval(() => {
      getLatestSessions()
    }, 1000 * 10)

    return () => clearInterval(apiCallInterval)
  }, [])

  /**
   * Fetch latest sessions
   */
  const getLatestSessions = async () => {
    setLoading(true)

    // Fetch latest sessions from the API
    const { data } = await sessionService.latest()

    setLoading(false)
    setList(data)
  }

  const paxCounts = (sxn) => {
    // Count how many is of type 'Adult' and 'Child'
    const adults = sxn.passengers.filter((p) => p.type === 'Adult').length
    const children = sxn.passengers.filter((p) => p.type === 'Child').length

    return `${adults} Adult${adults > 1 ? 's' : ''}, ${children} Child${
      children > 1 ? 'ren' : ''
    }`
  }

  return (
    <div id="page-sessions">
      <Card className="w-100">
        <Card.Header className="bg-light fw-bold">
          <div className="d-flex align-items-center gap-2">
            Recently Active Sessions
          </div>
        </Card.Header>
        <Card.Body>
          <div className="timeline-vertical py-0">
            <Table responsive striped hover className="bg-white">
              <thead>
                <tr>
                  <th scope="col">
                    <h6 className="text-700">ID</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700">Cruise ID</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700">Step</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700">Category (Cabin Grade)</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700">Full amount</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700">Passengers</h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {loading && (
                    <td colSpan={COL_COUNT} className="text-center">
                      <Spinner />
                    </td>
                  )}
                </tr>
                {!loading &&
                  (list.length ? (
                    list.map((sxn) => (
                      <tr key={sxn.id} className="align-middle fs-10">
                        <td className="text-nowrap text-primary fw-semibold">
                          {app.bookingSessionPrefix}
                          {sxn.id}
                        </td>
                        <td className="text-nowrap">{sxn.cruise_id}</td>
                        <td className="text-nowrap">
                          <SubtleBadge pill bg="success">
                            {steps[sxn.max_step_reached]}
                          </SubtleBadge>
                        </td>
                        <td className="text-nowrap">
                          {sxn.category
                            ? `${sxn.category} (${sxn.cabin_grade})`
                            : 'No category yet'}
                        </td>
                        <td className="text-nowrap">
                          {monify(sxn.full_amount)}
                        </td>
                        <td className="text-nowrap">{paxCounts(sxn)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={COL_COUNT} className="fs-10 text-center">
                        No Data
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
