import {
  faBook,
  faChartLine,
  faFileAlt,
  faOutdent,
  faPodcast,
  faWrench
} from '@fortawesome/free-solid-svg-icons'

const ROOT_PATH = '/simplycruises'

export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: `${ROOT_PATH}/`,
    }
  ]
}
export const appRoutes = {
  label: 'app',
  children: [
    {
      name: 'Contacts',
      icon: 'user',
      to: `${ROOT_PATH}/contacts`,
      active: true
    },
    {
      name: 'Reports',
      icon: faFileAlt,
      to: `${ROOT_PATH}/reports`,
      active: true
    },
    {
      name: 'Bookings',
      icon: faBook,
      to: `${ROOT_PATH}/bookings`,
      active: true
    },
    {
      name: 'Live',
      icon: faPodcast,
      to: `${ROOT_PATH}/sessions`,
      active: true
    },
    {
      name: 'Booking Stats',
      icon: faChartLine,
      to: `${ROOT_PATH}/booking-stats`,
      active: true
    },
  ]
}

export const pagesRoutes = {
  label: 'More..',
  children: [
    {
      name: 'Tools',
      icon: faWrench,
      to: `${ROOT_PATH}/tools`,
      active: false
    },
    {
      name: 'Back To CRMs',
      icon: faOutdent,
      to: '/',
      active: false
    },
  ]
}

const sitemap = [
  dashboardRoutes,
  appRoutes,
  pagesRoutes,
]

export default sitemap
