import { callExternalApi } from 'services/external-api.service'

const baseEndpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm`
const bookingsEndpoint = `${baseEndpoint}/sessions`

/**
 * Gets the latest bookings.
 */
export const latest = async () =>
  callExternalApi({
    config: {
      url: `${bookingsEndpoint}/latest`,
      method: 'GET',
    },
  })

const sessionService = {
  latest,
}

export default sessionService
