export const version = '1'
export const navbarBreakPoint = 'xl' // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg'
export const themeVariants = ['light', 'dark', 'auto']
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  theme: 'light',
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent'
}

// `app`-related configs
export const app = {
  bookingPrefix: 'SB', // Simply Cruises Booking
  contactPrefix: 'SC', // Simply Cruises Contact
  ledgerPrefix: 'SL', // Simply Cruises Ledger
  notePrefix: 'SN', // Simply Cruises Note
  passengerPrefix: 'SP', // Simply Cruises Passenger
  transactionPrefix: 'ST', // Simply Cruises Transaction
  bookingSessionPrefix: 'SBS', // Simply Cruises Booking Session

  env: process.env.REACT_APP_ENV || 'local',

  accessTokenKey: process.env.REACT_APP_ACCESS_TOKEN_KEY || 'accessToken',

  // Agency
  agencyId: process.env.REACT_APP_AGENCY_ID || 'UKP8507',
  agencyName: process.env.REACT_APP_AGENCY_NAME || 'Simply Cruises',

  // SimplyCRM App ID
  appId: process.env.REACT_APP_APP_ID || 'simplycrm',

  /**
   * Booking type mapping
   */
  bookingType: {
    manual: {
      key: 'manual',
      label: 'MNL',
      fullLabel: 'Manual',
      textClass: 'text-info',
      bgClass: 'bg-info',
      variant: 'info',
    },

    online: {
      key: 'online',
      label: 'OLN',
      fullLabel: 'Online',
      textClass: 'text-success',
      bgClass: 'bg-success',
      variant: 'success',
    }
  },

  /**
   * Organisation mapping
   */
  orgs: {
    MT: {
      key: 'MT',
      label: 'MT',
      fullLabel: 'Model Tourism Ltd',
      textClass: 'text-success',
      bgClass: 'bg-success',
      variant: 'success',
    },

    EH: {
      key: 'EH',
      label: 'EH',
      fullLabel: 'Excellent Holidays Ltd',
      textClass: 'text-info',
      bgClass: 'bg-info',
      variant: 'info',
    }
  },

  morphKeys: {
    BOOKING: 'booking',
    CONTACT: 'contact',
  },

  status: {
    BKD: {
      key: 'BKD',
      textClass: 'text-success',
      bgClass: 'bg-success',
      variant: 'success',
    },

    CXL: {
      key: 'CXL',
      textClass: 'text-danger',
      bgClass: 'bg-danger',
      variant: 'danger',
    },

    OPT: {
      key: 'OPT',
      textClass: 'text-warning',
      bgClass: 'bg-warning',
      variant: 'warning',
    },

    SLD: {
      key: 'SLD',
      textClass: 'text-secondary',
      bgClass: 'bg-secondary',
      variant: 'secondary',
    }
  },

  /**
   * Ledger status mapping
   */
  ledgerStatus: {
    settled: {
      key: 'settled',
      label: 'Settled',
      textClass: 'text-success',
      bgClass: 'bg-success',
      variant: 'success',
    },

    outstanding: {
      key: 'outstanding',
      label: 'Outstanding',
      textClass: 'text-secondary',
      bgClass: 'bg-secondary',
      variant: 'secondary',
    }
  },

  /**
   * Experience code mapping
   */
  experience: {
    EXP1: 'Bella',
    EXP2B: 'Fantastica',
    EXP3B: 'Aurea',
    EXPYCB: 'MSC Yacht Club',
  },

  /**
   * Arrival type mapping.
   */
  arrivalType: {
    A: 'Airport',
    P: 'Port',
  },

  ledgerTransactionType: {
    CRUISE: 'CRUISE',
    DEPOSIT: 'DEPOSIT',
    COMP: 'COMP',
    DISCOUNT: 'DISCOUNT',
    EXCURSION: 'EXCURSION',
    FEE: 'FEE',
    ITEM: 'ITEM',
    PAYMENT: 'PAYMENT',
    REFUND: 'REFUND',
    REST: 'REST',
    UPGRADE: 'UPGRADE',
    CANC: 'CANC',
    PAYFAIL: 'PAYFAIL',
  },

  ledgerTransactionDesc: {
    CRUISE: 'Opening Balance',
    DEPOSIT: 'Initial Deposit',
    COMP: 'Complimentary Item',
    DISCOUNT: 'Booking Discount',
    EXCURSION: 'Cruise Excursion',
    FEE: 'Misc Fee',
    ITEM: 'Cruise Item',
    PAYMENT: 'Payment Received',
    REFUND: 'Refund',
    REST: 'Restaurant Item',
    UPGRADE: 'Cabin Upgrade',
    CANC: 'Cancellation',
    PAYFAIL: 'Failed Payment',
  },
}

// We need to put config in a separate variable to solve `no-anonymous-default-export` lint error
export const config = {
  app,
  version,
  navbarBreakPoint,
  topNavbarBreakpoint,
  settings
}

export default config
