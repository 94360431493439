import qs from 'query-string'
import { callExternalApi } from 'services/external-api.service'

const baseEndpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm`
const bookingStatsEndpoint = `${baseEndpoint}/booking-stats`

/**
 * Gets booking stats by month.
 */
export const getAll = async (query: { month: number; year: number }) =>
  callExternalApi({
    config: {
      url: `${bookingStatsEndpoint}?${qs.stringify(query)}`,
      method: 'GET',
    },
  })

const bookingStatService = {
  getAll,
}

export default bookingStatService
