import { useMemo } from 'react'
import dayjs from 'dayjs'

import useUtils from 'hooks/useUtils'

const useReport = () => {
  const { monthName } = useUtils()

  const currentYear = useMemo(() => dayjs().year(), [])

  /**
   * Generates options for month selector
   */
  const monthOptions = useMemo(() => {
    const months = []

    for (let month = 1; month <= 12; month++) {
      months.push({
        label: monthName(month),
        value: month,
      })
    }

    return months
  }, [])

  /**
   * Generates options for year selector
   */
  const yearOptions = useMemo(() => {
    const years = []

    const startYear = currentYear - 3
    const endYear = currentYear + 3

    for (let year = startYear; year <= endYear; year++) {
      years.push(year)
    }

    return years
  }, [])

  /**
   * Generates options for last 5 years
   */
  const last5YearOptions = useMemo(() => {
    const years = []

    const startYear = currentYear - 5
    const endYear = currentYear

    for (let year = startYear; year <= endYear; year++) {
      years.push(year)
    }

    return years
  }, [currentYear])

  return {
    currentYear,
    monthOptions,
    yearOptions,
    last5YearOptions,
  }
}

export default useReport
