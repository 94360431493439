import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useMemo, useState } from 'react'
import { Card, Form, Spinner, Table } from 'react-bootstrap'

import useUtils from 'hooks/useUtils'
import useReport from 'hooks/useReport'
import dayjs from 'dayjs'
import bookingStatService from 'services/booking-stat.service'
import AppTooltip from 'components/common/AppTooltip'
import BookingStatCruisesModal from 'modules/simple-crm/components/booking-stats/BookingStatCruisesModal'

const COLS_COUNT = 9

export default function BookingStatsPage() {
  const { monify, formatDate } = useUtils()

  const {
    currentYear,
    monthOptions,
    last5YearOptions: yearOptions,
  } = useReport()

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const [showCruisesFor, setShowCruisesFor] = useState(null)

  // We add 1 to month because dayjs month starts at 0
  const currentMonth = useMemo(() => dayjs().month() + 1, [])
  const [selectedMonth, setSelectedMonth] = useState(currentMonth)

  const [selectedYear, setSelectedYear] = useState(currentYear)

  // Get stats on initial load, also when selected month or year changes
  useEffect(() => {
    getStats()
  }, [selectedMonth, selectedYear])

  /**
   * Get stats from the API
   */
  const getStats = async () => {
    setResults([])
    setLoading(true)

    const { data } = await bookingStatService.getAll({
      month: selectedMonth,
      year: selectedYear,
    })

    setResults(data)
    setLoading(false)
  }

  return (
    <Card>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <p className="fw-bold m-0">Booking Statistics</p>
          <div className="d-flex flex-between-center gap-3">
            <Form.Group className="d-flex align-items-center gap-2">
              <FontAwesomeIcon icon="filter" className="me-2" />
              <Form.Select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                disabled={loading}
              >
                {monthOptions.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="d-flex align-items-center gap-2">
              <Form.Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                disabled={loading}
              >
                {yearOptions.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
        </div>
        <hr />
        <Table responsive striped hover>
          <thead>
            <tr className="text-center">
              <th scope="col">
                <h6 className="text-700 fw-semibold">Date</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Sessions</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Total value</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Booked value</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Step 2</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Step 3</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Step 4</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Step 5</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Actions</h6>
              </th>
            </tr>
          </thead>
          <tbody className="fs-10">
            <tr>
              {loading && (
                <td colSpan={COLS_COUNT} className="text-center">
                  <Spinner />
                </td>
              )}
            </tr>
            <tr>
              {!loading && !results.length && (
                <td colSpan={COLS_COUNT} className="text-center">
                  Empty results
                </td>
              )}
            </tr>
            {results.map((result, index) => (
              <tr key={index} className="text-center">
                <td className="fw-bold">{formatDate(result.date, 'DD MMM')}</td>
                <td>{result.total_sessions}</td>
                <td>{monify(result.total_sessions_value)}</td>
                <td>
                  {monify(result.booked_sessions_value)} (
                  {result.booked_sessions_count})
                </td>
                <td>{result.step_2_count}</td>
                <td>{result.step_3_count}</td>
                <td>{result.step_4_count}</td>
                <td>{result.step_5_count}</td>
                <td>
                  <AppTooltip
                    title={`View Cruise-IDs`}
                    id={`cruises-${result.id}-tt`}
                  >
                    <FontAwesomeIcon
                      icon="table"
                      className="cursor-pointer me-3"
                      onClick={() => setShowCruisesFor(result)}
                    />
                  </AppTooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
      {showCruisesFor && (
        <BookingStatCruisesModal
          onClose={() => setShowCruisesFor(null)}
          stat={showCruisesFor}
        />
      )}
    </Card>
  )
}
